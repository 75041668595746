

































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import {
  useComplementaryProducts,
  useProductPrice
} from '~/composables';
import ComplementaryProductCart
  from '~/components/molecules/Product/ComplementaryProducts/ComplementaryProductCart/ComplementaryProductCart.vue';

export default defineComponent({
  name: 'ComplementProducts',
  components: {
    ComplementaryProductCart
  },
  setup() {
    const { complementaryProducts, complementaryBaseProduct } = useComplementaryProducts();
    const { getFormatedPrice } = useProductPrice();

    const selectedProductPrices = ref<number>(0);

    const baseProductPrice = computed<number>(() => +getFormatedPrice(complementaryBaseProduct.value));

    const totalPrice = computed<number>(() => {
      return baseProductPrice.value + selectedProductPrices.value;
    });

    const onProductSelectionChanged = (price: number) => {
      selectedProductPrices.value += price;
    };

    return {
      totalPrice,
      onProductSelectionChanged,
      complementaryBaseProduct,
      complementaryProducts,
      baseProductPrice
    };
  }
});
