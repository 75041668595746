

















import { defineComponent } from '@nuxtjs/composition-api';
import CartSidebarButtons from '~/components/molecules/CartSidebars/CartSidebarButtons/CartSidebarButtons.vue';
import CartSidebarHeading from '~/components/atoms/CartSidebars/CartSidebarHeading.vue';
import ComplementaryProducts
  from '~/components/organisms/ComplementaryProducts/ComplementaryProducts/ComplementaryProducts.vue';
import { useCartSidebars } from '~/composables';

export default defineComponent({
  name: 'ComplementaryProductsInner',
  components: {
    CartSidebarHeading,
    CartSidebarButtons,
    ComplementaryProducts
  },
  setup(_, { emit }) {
    const { handleGoToCheckout } = useCartSidebars();

    const handlePrimaryButton = () => {
      handleGoToCheckout();
      handleClose(false);
    };
    const handleClose = (shouldOpenCartSidebar: boolean = true) => {
      emit('clickClose', shouldOpenCartSidebar);
    };

    return {
      handlePrimaryButton,
      handleClose
    };
  }
});
