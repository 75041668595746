



















































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';
import ProductCardBadges from '~/components/molecules/Product/ProductCardBadges.vue';
import ProductCardPricing from '~/components/molecules/Product/ProductCardPricing/ProductCardPricing.vue';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import buildProductRating from '~/helpers/product/buildProductRating';
import { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { VERTICAL_PRODUCT_CARD } from '~/constants/productCard';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';
import { PRODUCT_PRICING_SIZE } from '~/constants/productCardPricing';
import CheckBox from '~/components/atoms/Buttons/CheckBox/CheckBox.vue';

export default defineComponent({
  name: 'ProductCardTile',
  components: {
    CheckBox,
    Link,
    TwicpicsImage,
    ProductCardBadges,
    ProductCardPricing,
    ProductAverageReview: () => import(/* webpackChunkName: "ProductAverageReview" */
      '~/components/atoms/Product/ProductAverageReview.vue'),
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue')
  },
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    discountedPrice: {
      type: Number,
      default: null
    },
    isChecked: {
      type: Boolean,
      default: true
    },
    reviewsCount: {
      type: Number,
      default: 0
    },
    rating: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    tags: {
      type: Array as PropType<ProductTag[]>,
      default: () => {
        return [];
      }
    },
    brand: {
      type: String,
      default: ''
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const showCardBadge = computed(() => props.tags?.length);
    const ratingToShow = computed(() => {
      return buildProductRating({
        averageRating: props.rating,
        count: props.reviewsCount
      });
    });

    const onClick = () => {
      emit('product-clicked');
    };

    return {
      LinkType,
      ratingToShow,
      showCardBadge,
      VERTICAL_PRODUCT_CARD,
      ENERGY_RATING_SIZE,
      PRODUCT_PRICING_SIZE,
      onClick
    };
  }
});
